import { API } from "../modules/Api.js";
import Logger from "../modules/Logger";
import { setAuth } from "./loginAction.js";

export const TOGGLE_SWITCH_PROFILE_CONTENT = "TOGGLE_SWITCH_PROFILE_CONTENT";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";
export const START_GET_PROFILE = "START_GET_PROFILE";
export const START_SAVE_PROFILE = "START_SAVE_PROFILE";

export function switchProfileContent(isSwitch, type = "") {
  return (dispatch) => {
    dispatch(toggleSwitchProfileContent(isSwitch, type));
  };
}

export function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

function startGetProfile() {
  return {
    type: START_GET_PROFILE,
  };
}

// Get profile
export const getProfile = () => (dispatch) => {
  dispatch(startGetProfile());
  return API.get("auth/profile").then(
    (response) => {
      dispatch(setAuth());
      dispatch(obtainProfileData(response.data));
    },
    (error) => {
      Logger.info(error);
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );
};

function startSaveProfile() {
  return { type: START_SAVE_PROFILE };
}

// Save profile
export const saveProfile = ({ name, clinic, phone_number, shipping_address, country }) => (dispatch) => {
  dispatch(startSaveProfile());
  return API.put("auth/profile", {
    name,
    clinic,
    phone_number,
    shipping_address,
    country,
  }).then(
    (response) => {
      dispatch(obtainProfileData(response.data));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainProfileError(error));
    },
  );
};

function toggleSwitchProfileContent(isSwitch, type) {
  return {
    type: TOGGLE_SWITCH_PROFILE_CONTENT,
    payload: { isSwitch, type },
  };
}
