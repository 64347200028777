import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import "./index.scss";

export const FullPageLoader = ({ withOverlay }) => {
  return (
    <Box
      css={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}
      className={withOverlay ? "fullPageLoader_withOverlay" : ""}
    >
      <CircularProgress color={"secondary"} size={120} />
    </Box>
  );
};
