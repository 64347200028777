import Logger from "../modules/Logger";

export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const SET_DIALOG_DATA = "SET_DIALOG_DATA";

function setDialog(dialogName, isShow, data = null) {
  return {
    name: dialogName,
    type: "TOGGLE_DIALOG",
    isShow: isShow,
    data: isShow ? data : null,
  };
}

export const toggleDialog = (dialogName, isShow, props) => dispatch => {
  Logger.info(`SetDialog "${dialogName}": state: "${isShow}" with data`, props);
  dispatch(setDialog(dialogName, isShow, props));
};
