import { combineReducers } from "redux";

import infoPopupReducer from "./infoPopupReducer";
import loginReducer from "./loginReducer";
import profileReducer from "./profileReducer";
import { createDialogsReducer } from "./dialogsReducer";

export default combineReducers({
  infoPopup: infoPopupReducer,
  login: loginReducer,
  profile: profileReducer,
  ...createDialogsReducer(),
});
